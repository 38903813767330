import { isFunction } from 'lodash-es';
import { Modal, ModalOverlay, ModalOverlayProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

export function _Modal({
  children,
  className,
  isDismissable = true,
  overlayClassName,
  dataTestid,
  ...props
}: ModalOverlayProps & { overlayClassName?: string; dataTestid?: string }) {
  return (
    <ModalOverlay
      className={twMerge(
        'fixed inset-0 z-50 flex h-[var(--visual-viewport-height)] w-screen items-center justify-center',
        overlayClassName,
      )}
      isDismissable={isDismissable}
      {...props}
      style={{ backgroundColor: 'rgba(62, 58, 55, .4)' }}>
      <Modal
        className={(bag) =>
          twMerge(
            'shadow-8 max-h-full w-full max-w-72 rounded-xl bg-white bg-clip-padding text-left align-middle focus:outline-none sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl',
            isFunction(className) ? className(bag) : className,
          )
        }
        data-testid={dataTestid}>
        {children}
      </Modal>
    </ModalOverlay>
  );
}

export { _Modal as Modal };
